const data = [
  {
    label: "Development Skills",
    description: "Skills I acquired as a Developer",
    categories: [
      {
        title: "Front-end Development",
        skills: [
          "HTML5/CSS3/JS/TS",
          "ReactJS",
          "GatsbyJS",
          "NextJS",
          "VueJS - Vue3",
          "Wordpress / SquareSpace / Shopify / Wix",
        ],
      },
      {
        title: "Backend Development",
        skills: ["Golang", "Python", "Python: Django", "JS/TS: NodeJS", "JS: ExpressJS"],
      },
      {
        title: "Databases",
        skills: ["Firebase(RTDB,Firestore,Storage)","MongoDB", "PostGreSQL", "MySQL", "Graphql, Redis, BoltDB", "OpenSearch/ElasticSearch"],
      },
      {
        title: "General Development",
        skills: ["Google Cloud Platform (GCP)", "Amazon Web Services (AWS) ", "Kubernetes", "Docker", "Git", "Atlassian"],
      },
      {
        title: "Tools",
        skills: ["Jira", "Confluence", "Mockoon", "Postman", "PSequel", "Bitbucket", "Monday", "Github", "Gitlab", "Neovim"],
      },
      { title: "Mobile Development",
        skills: [ "Dart", "Flutter", "React Native"],
      },
    ],
  },
  {
    label: "Design Skills",
    description: "Skills I learned as a Designer",
    categories: [
      {
        title: "Adobe Suite",
        skills: [
          "Adobe Illustrator",
          "Adobe After Effects",
          "Adobe Photoshop",
          "Adobe XD",
        ],
      },
      {
        title: "Others",
        skills: ["Figma", "Marvel"],
      },
    ],
  },
]

export default data
