import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ExtLink, InlinePageLink } from "../components/atoms"
import Contact from "../components/contact"
import Emoji from "a11y-react-emoji"
import SkillGrid from "../components/skillGrid"
import skills from "../data/skills"

const SkillsList = () => {
  return (
    <section>
      <div className="mt-2 flex flex-col space-y-0">
        {skills.map(data => {
          return (
            <SkillGrid
              key={data.label}
              heading={data.label}
              description={data.description}
              categories={data.categories}
            />
          )
        })}
      </div>
    </section>
  )
}

const About = _ => {
  return (
    <Layout activePage="about">
      <Seo title="About" />
      <div classNameName="container">
        <article classNameName="prose-lg">
          <h2 className="relative mt-12 mb-3 text-2xl font-bold text-accent">
            About _{" "}
          </h2>
          <p>
            Hi, I’m <strong>Gabriel Lee</strong> from{" "}
            <Emoji symbol="🇸🇬" label="Singapore" />. I'm a senior software engineer at{" "}
            <ExtLink link="https://transcelestial.com/">Transcelestial.📡 🚀 🛰️</ExtLink>{" "}
            In my free time, I learn new technologies to build hobby projects.
          </p>
          <p>
            Currently pursuing a Masters in <b>Computing Systems</b>.{" "}
            <Emoji symbol="👨‍💻" label="Programmer" />. Loves a good cup of coffee
            <Emoji symbol="☕" label="Coffee" />. Enjoys a good drink <Emoji symbol="🍶" label="Sake" />.
          </p>
          <br />

          <h2 className="relative mt-6 mb-3 text-2xl font-bold text-accent">
            CV
          </h2>
          <div>
            {" "}
            A copy of my CV can be{" "}
            <InlinePageLink to="/cv">seen here</InlinePageLink> .
            <div className="text-sm mt-1">
              This file was last updated on Friday 9 August 2024.
            </div>
          </div>
          <SkillsList />
        </article>
      </div>
      <Contact />
    </Layout>
  )
}

export default About
